export const motionPageSlideInOutProps = {
  initial: { x: '10%', opacity: 0 },
  animate: { x: '0', opacity: 1 },
  exit: { y: '-10%', opacity: 0 },
  transition: { duration: 0.25 },
}

export const motionPageSlideFromRightProps = {
  initial: { x: '100%' },
  animate: { x: '0' },
  transition: { duration: 0.25, ease: 'easeInOut' },
}

export const opacityAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}
